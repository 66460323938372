import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavBarAlwyasTop from '../NavBar/NavbarAlwyasTop.js';
import Contact from '../Contact/Contact.js';
import Bottom from '../Bottom/Bottom.js';

class ContactPage extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        document.title = 'Kontakt - Kancelaria Adwokacka - adw. Jakub Boruta';
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <>
                <NavBarAlwyasTop />
                <Contact soloSite={true} />
                <Bottom />
            </>
        );
    }
}
export default ContactPage;
