import { Container, Col, Row } from 'react-bootstrap';
import iconinwalifa from '../Images/iconinwalifa.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Contact.css';

function Contact(props) {
    return (
        <div className="contact-body">
            <Container>
                <div className="contact-container">
                    <Row style={{marginTop: props.soloSite ? "100px" : "none"}}>
                        <Col lg={5}>
                            <div className="contact-title">
                                Kontakt
                            </div>
                            <div className="contact-line">
                                <hr/>
                            </div>    
                            <div className="contact-content">
                                <p>
                                    Kancelaria Adwokacka
                                    <br/>
                                    Adwokat Jakub Boruta
                                    <br/>
                                    ul. Transportowa 14 lok. 4, 15-673 Białystok
                                </p>
                                <p>
                                    Tel. 660 717 588
                                    <br/>
                                    email:&nbsp;
                                    <a href="mailto:jakub@adwokatboruta.pl">jakub@adwokatboruta.pl</a>
                                </p>
                            </div>
                            <div className="contact-info">
                                <div className="contact-info-text">
                                    Klientów obsługujemy w języku polskim, rosyjskim i angielskim
                                </div>
                                <div className="contact-info-img">
                                    <img 
                                        decoding="async" 
                                        //src="https://adwokatboruta.pl/wp-content/uploads/2021/11/iconinwalifa.png" 
                                        src={iconinwalifa}
                                        alt="" 
                                        loading="lazy" 
                                        width="39" 
                                        height="43" 
                                    />
                                </div>
                                <div className="contact-info-text">
                                    LOKAL PRZYJAZNY OSOBOM NIEPEŁNOSPRAWNYM 
                                </div>
                            </div>
                        </Col>
                        <Col lg={7}>
                            <div className="contact-iframe">
                                <iframe
                                    width="100%"
                                    height="100%"
                                    frameBorder="0"
                                    scrolling="no"
                                    marginHeight="0"
                                    marginWidth="0"
                                    src="https://maps.google.com/maps?q=ul.%20Transportowa%2014%20lok.%204%2C%2015-673%20Bia%C5%82ystok&amp;t=m&amp;z=15&amp;output=embed&amp;iwloc=near"
                                    title="ul. Transportowa 14 lok. 4, 15-673 BiaLystok"
                                    aria-label="ul. Transportowa 14 lok. 4, 15-673 BiaLystok">
                                </iframe>
                            </div>
                            
                        </Col>                     
                    </Row>
                </div>
            </Container>       
        </div>
    );
}

export default Contact;