import React from 'react';
import { Container, Row } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Services.css';

class ServicesTemplate extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="services-body">
                <Container >
                    <div className="services-container">
                        <Row className="services-row" style={{ marginTop: this.props.soloSite ? "100px" : "none" }}>
                            {this.props.service}
                        </Row>
                    </div>
                </Container>
            </div>
        );
    }
}

export default ServicesTemplate;
