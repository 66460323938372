import { Container, Row } from 'react-bootstrap';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import logonastrone1 from '../Images/logonastrone1.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Bottom.css';
import NavBar from '../NavBar/NavBar.js';

function Bottom() {
    return (
        <div className="bottom-body">
            <Container>
                <div className="bottom-container">
                    <Row>
                        <div className="bottom-logo">
                            <img 
                                src={logonastrone1}
                                //src="https://adwokatboruta.pl/wp-content/uploads/2021/11/logonastrone1.png" 
                                alt="" 
                                loading="lazy" 
                                width="144" 
                                height="108" 
                            />
                        </div>
                        <div className="bottom-navbar">
                            <ul>
                                <li>
                                    <a href="/">Kancelaria</a>
                                </li>
                                <li>
                                    <Dropdown>
                                        <Dropdown.Toggle>
                                        Usługi Prawne
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                        <Dropdown.Item href="#/prawo-gospodarcze">Prawo gospodarcze i obsługa prawna przedsiębiorców</Dropdown.Item>
                                                                <Dropdown.Item href="#/prawo-cywilne">Prawo Cywilne</Dropdown.Item>
                                                                <Dropdown.Item href="#/prawo-karne">Prawo Karne</Dropdown.Item>
                                                                <Dropdown.Item href="#/prawo-rodzinne">Prawo Rodzinne</Dropdown.Item>
                                                                <Dropdown.Item href="#/prawo-spadkowe">Prawo Spadkowe</Dropdown.Item>
                                                                <Dropdown.Item href="#/prawo-sportowe">Prawo sportowe i organizacje pozarządowe</Dropdown.Item>
                                                                <Dropdown.Item href="#/mediacje">Mediacje</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </li>
                                <li>
                                    <a href="#/Kontakt">Kontakt</a>
                                </li>
                            </ul>
                        </div>
                        <div className="bottom-info">
                            <h6>Copyright © { new Date().getFullYear() } Kancelaria adwokacka adw. Jakub Boruta. All Right Reserved. </h6>
                        </div>
                    </Row>
                </div>
            </Container>       
        </div>
  );
}

export default Bottom;
