import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Services.css';

class PrawoSpadkowe extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        document.title = 'Prawo Spadkowe - Kancelaria Adwokacka - adw. Jakub Boruta';
        window.scrollTo(0, 0)
    }
    render() {
        return (
            <>
                <div className="services-title">
                    Prawo Spadkowe
                </div>
                <div className="services-line">
                    <hr />
                </div>
                <div className="services-content">
                    <p>
                        Kancelaria Adwokacka adw. Jakuba Boruta świadczy usługi w zakresie prawa spadkowego, w tym w sprawach o stwierdzenie nabycia spadku (ustawowe i testamentowe), czy też dział spadku, a w szczególności: 
                    </p>
                    <ul>
                        <li>
                            Reprezentacja na każdym etapie postępowania 
                        </li>
                        <li>
                            Sporządzanie wszelkiego rodzaju pism procesowych oraz ugód
                        </li>
                        <li>
                            Sporządzanie wniosków, pozwów oraz zażaleń, apelacji i kasacji
                        </li>
                        <li>
                            Reprezentacja klientów w postępowaniu egzekucyjnym
                        </li>
                    </ul>
                    <p>
                        Jeśli potrzebują Państwo pomocy w sprawie spadkowej, zapraszamy do kontaktu i zapoznania się z ofertą Kancelarii w tym zakresie.  
                    </p>
                </div>
            </>
        );
    }
}

export default PrawoSpadkowe;