import { Row, Nav, Navbar, NavDropdown  } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './NavBar.css'
import logonastrone1 from '../Images/logonastrone1.png';

function NavBar(props) {
    return (
        <Row className="navbar-row">
            <Navbar expand="md" className={props.transparent ? "navbar transparent" : "navbar" }>
                <Navbar.Brand>
                    <img height={props.logoHeight ? props.logoHeight : "100%"} src={logonastrone1} />
                    {/*<img height={props.logoHeight ? props.logoHeight : "100%" } src="https://adwokatboruta.pl/wp-content/uploads/2021/11/logonastrone1.png" />*/}
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse className="navbar-collapse">
                    <Nav className="me-auto">
                        <Nav.Link className="nav-link" href="/">Kancelaria</Nav.Link>
                        <NavDropdown title="Usługi Prawne">
                            <NavDropdown.Item href="#/prawo-gospodarcze">Prawo gospodarcze i obsługa prawna przedsiębiorców</NavDropdown.Item>
                            <NavDropdown.Item href="#/prawo-cywilne">Prawo Cywilne</NavDropdown.Item>
                            <NavDropdown.Item href="#/prawo-karne">Prawo Karne</NavDropdown.Item>
                            <NavDropdown.Item href="#/prawo-rodzinne">Prawo Rodzinne</NavDropdown.Item>
                            <NavDropdown.Item href="#/prawo-spadkowe">Prawo Spadkowe</NavDropdown.Item>
                            <NavDropdown.Item href="#/prawo-sportowe">Prawo sportowe i organizacje pozarządowe</NavDropdown.Item>
                            <NavDropdown.Item href="#/mediacje">Mediacje</NavDropdown.Item>
                        </NavDropdown>
                        <Nav.Link className="nav-link" href="#/kontakt">Kontakt</Nav.Link>                      
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </Row>
    );
}

export default NavBar;