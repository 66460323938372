import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Services.css';

class PrawoSportowe extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        document.title = 'Prawo sportowe i organizacje pozarządowe - Kancelaria Adwokacka - adw. Jakub Boruta';
        window.scrollTo(0, 0)
    }
    render() {
        return (
            <>
                <div className="services-title">
                    Prawo sportowe i organizacje pozarządowe
                </div>
                <div className="services-line">
                    <hr />
                </div>
                <div className="services-content">
                    <p>
                        Kancelaria Adwokacka adw. Jakuba Boruta świadczy usługi w zakresie spraw klubów sportowych, fundacji i stowarzyszeń oraz świadczy ich kompleksową obsługę prawną, a w szczególności: 
                    </p>
                    <ul>
                        <li>
                            Reprezentacja na każdym etapie postępowania sądowego i egzekucyjnego
                        </li>
                        <li>
                            Negocjacje
                        </li>
                        <li>
                            Sporządzenie umów oraz opinii prawnych
                        </li>
                        <li>
                            Kompleksowa obsługa
                        </li>
                    </ul>
                    <p>
                        Jeśli potrzebują Państwo pomocy w sprawie gospodarczej lub szukają Państwo obsługi prawnej, zapraszamy do kontaktu i zapoznania się z ofertą Kancelarii w tym zakresie. 
                    </p>
                </div>
            </>
        );
    }
}

export default PrawoSportowe;