import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Services.css';

class PrawoRodzinne extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        document.title = 'Prawo Rodzinne - Kancelaria Adwokacka - adw. Jakub Boruta';
        window.scrollTo(0, 0)
    }
    render() {
        return (
            <>
                <div className="services-title">
                    Prawo Rodzinne
                </div>
                <div className="services-line">
                    <hr />
                </div>
                <div className="services-content">
                    <p>
                        Kancelaria Adwokacka adw. Jakuba Boruta świadczy usługi w zakresie prawa rodzinnego, w tym w sprawach o rozwód, alimenty, ustalenie kontaktów i władzy rodzicielskiej oraz o podział majątku, które są skierowane do osób prywatnych, a w szczególności:  
                    </p>
                    <ul>
                        <li>
                            Reprezentacja na każdym etapie postępowania 
                        </li>
                        <li>
                            Sporządzanie wszelkiego rodzaju pism procesowych oraz wezwań, ugód i opinii prawnych
                        </li>
                        <li>
                            Sporządzanie wniosków, pozwów oraz zażaleń, apelacji i kasacji
                        </li>
                        <li>
                            Reprezentacja klientów w postępowaniu egzekucyjnym
                        </li>
                    </ul>
                    <p>
                        Jeśli potrzebują Państwo pomocy w sprawie rodzinnej, zapraszamy do kontaktu i zapoznania się z ofertą Kancelarii w tym zakresie. 
                    </p>
                </div>
            </>
        );
    }
}

export default PrawoRodzinne;