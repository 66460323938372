import { Container } from 'react-bootstrap';
import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './HomePage.css';
import NavBar from '../NavBar/NavBar.js';
import NavBarAlwyasTop from '../NavBar/NavbarAlwyasTop.js';
import Header from '../Header/Header.js';
import Kancelaria from '../Kancelaria/Kancelaria.js';
import Contact from '../Contact/Contact.js';
import Bottom from '../Bottom/Bottom.js';

class HomePage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            IsMobile: false,
        }
    }

    componentDidMount() {
        document.title = 'Kancelaria Adwokacka - adw. Jakub Boruta';
        
        let width = window.innerWidth;
        
        if (width > 768) {
            this.setState({IsMobile: false})            
        }
        else {
            this.setState({IsMobile: true})
        }
    }

    render() {
        return (
            <div>
                <div className="HomePage-header">
                    {this.state.IsMobile && <NavBarAlwyasTop />}    
                    <Container>
                    {!this.state.IsMobile && <NavBar transparent={true}/>}              
                        <Header />
                    </Container>
                </div>
                <Kancelaria />
                <Contact />
                <Bottom />
            </div>  
  );
    }
}
export default HomePage;

//function HomePage() {
//    return (
//            <>
//                <div className="HomePage-header">
//                    <Container>
//                    <NavBar transparent={true}/>
//                        <Header />
//                    </Container>
//                </div>
//                <Kancelaria />
//                <Services />
//                <Contact />
//                <Bottom />
//            </>  

//  );
//}

//export default HomePage;
