import { BrowserRouter, Routes, Route, HashRouter, Router, Navigate } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import ContactPage from './Contact/ContactPage.js'
import HomePage from './HomePage/HomePage.js'
import ServicePage from './Services/ServicePage.js';
import PrawoGospodarcze from './Services/PrawoGospodarcze.js';
import PrawoCywilne from './Services/PrawoCywilne.js';
import PrawoKarne from './Services/PrawoKarne.js';
import PrawoRodzinne from './Services/PrawoRodzinne.js';
import PrawoSpadkowe from './Services/PrawoSpadkowe.js';
import PrawoSportowe from './Services/PrawoSportowe.js';
import Mediacje from './Services/Mediacje.js';


function App() {
    return (
        <HashRouter>
            <Routes>
                <Route path="/"                  element={<HomePage/>}/>
                <Route path="/kontakt"           element={<ContactPage/>} />
                <Route path="/prawo-gospodarcze" element={ <ServicePage title="Prawo gospodarcze i obsługa prawna przedsiębiorców" service={ <PrawoGospodarcze /> } /> } />
                <Route path="/prawo-cywilne"     element={ <ServicePage title="Prawo Cywilne" service={ <PrawoCywilne /> } /> } />
                <Route path="/prawo-karne"       element={ <ServicePage title="Prawo Karne" service={<PrawoKarne /> } /> } />
                <Route path="/prawo-rodzinne"    element={ <ServicePage service={ <PrawoRodzinne /> } /> } />
                <Route path="/prawo-spadkowe"    element={ <ServicePage service={ <PrawoSpadkowe />} /> } />
                <Route path="/prawo-sportowe"    element={ <ServicePage service={ <PrawoSportowe /> } /> } />
                <Route path="/mediacje"          element={ <ServicePage service={ <Mediacje />} /> } />
                <Route path='*'                  element={ <Navigate to='/' />} />
            </Routes>
        </HashRouter>     
    );
}

export default App;
