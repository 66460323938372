import React, { Component } from "react";
import adwokat1 from '../Images/adwokat1.jpg';
import adwokat2 from '../Images/adwokat2.jpg';
import "./ImgSlider.css"; 

class ImgSlider extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showImgFirst: true,
        }
    }

    componentDidMount() {
        setTimeout(this.ChangeImg, 5000)
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.showImgFirst !== this.state.showImgFirst) {
            setTimeout(this.ChangeImg, 15000)
        }
    }

    ChangeImg = () => {
        this.setState({
            showImgFirst: !this.state.showImgFirst 
        });
    }

    render() {
        return (
        <div id="hcg-slider" className="hcg-slider">
            <div className="hcg-slide-container">
                <div className="hcg-slider-body">
                    <a className="hcg-slides animated" style={{display: this.state.showImgFirst ? "none" : "flex"}} >
                        {/* <span className="hcg-slide-number">1/5</span> */}
                        {/*<img src="https://adwokatboruta.pl/wp-content/uploads/elementor/thumbs/DSC_56661nastrone-pgs0cnmpdjr8jf9vdoah4yo202z8n4ze9mw7dgmps0.jpg" alt="image1"/>*/}
                            <img src={ adwokat1 } alt="image1"/>
                        {/* <span className="hcg-slide-text">image 1</span> */}
                    </a>
                    <a className="hcg-slides animated" style={{display: !this.state.showImgFirst ? "none" : "flex"}} >
                        {/* <span className="hcg-slide-number">2/5</span> */}
                            <img src={adwokat2}  alt="image2"/>
                        {/*<img src="https://adwokatboruta.pl/wp-content/uploads/elementor/thumbs/DSC_577011nastrone-pgs0yrs06813p55f8qfhavrh5fz4qrs1n3ggutu9ds.jpg" alt="image2"/>*/}
                        {/* <span className="hcg-slide-text">image 2</span> */}
                    </a>
                </div>
            </div>
        </div>   
    );
}
}
export default ImgSlider;