import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavBarAlwyasTop from '../NavBar/NavbarAlwyasTop.js';
import ServicesTemplate from './ServicesTemplate.js';
import Bottom from '../Bottom/Bottom.js';
import Contact from '../Contact/Contact.js';

class ServicePage extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                <NavBarAlwyasTop />
                <ServicesTemplate soloSite={true} service={this.props.service} />             
                <Contact />
                <Bottom />
            </>
        );
    }
}
export default ServicePage;
