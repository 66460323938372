import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Services.css';

class PrawoGospodarcze extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        document.title = 'Prawo gospodarcze i obsługa prawna przedsiębiorców - Kancelaria Adwokacka - adw. Jakub Boruta';
        window.scrollTo(0, 0)
    }
    
    render() {
        return (
            <>
                <div className="services-title">
                    Prawo gospodarcze i obsługa prawna przedsiębiorców
                </div>
                <div className="services-line">
                    <hr />
                </div>
                <div className="services-content">
                    <p>
                        Kancelaria Adwokacka adw. Jakuba Boruta świadczy usługi w zakresie prawa gospodarczego, które są skierowane zarówno do osób prowadzących jednoosobową działalność gospodarczą, jak i spółek prawa handlowego, które potrzebują pomocy prawnej doraźnej oraz stałej obsługi prawnej, a w szczególności: 
                    </p>
                    <ul>
                        <li>
                            Reprezentacja na każdym etapie postępowania sądowego i egzekucyjnego
                        </li>
                        <li>
                            Negocjacje
                        </li>
                        <li>
                            Sporządzenie umów oraz opinii prawnych
                        </li>
                        <li>
                            Kompleksowa obsługa podmiotów gospodarczych
                        </li>
                    </ul>
                    <p>
                        Jeśli potrzebują Państwo pomocy w sprawie gospodarczej lub szukają Państwo stałej obsługi prawnej, zapraszamy do kontaktu i zapoznania się z ofertą Kancelarii w tym zakresie. 
                    </p>
                </div>
            </>
        );
    }
}

export default PrawoGospodarcze;