import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Services.css';

class PrawoKarne extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        document.title = 'Prawo Karne - Kancelaria Adwokacka - adw. Jakub Boruta';
        window.scrollTo(0, 0)
    }
    render() {
        return (
            <>
                <div className="services-title">
                    Prawo Karne
                </div>
                <div className="services-line">
                    <hr />
                </div>
                <div className="services-content">
                    <p>
                        Kancelaria Adwokacka adw. Jakuba Boruta świadczy usługi w zakresie prawa karnego, unormowanego m. in. w kodeksie karnym, kodeksie karno – skarbowym, kodeksie w sprawach o wykroczenia oraz w innych aktach, jak również na etapie postępowania wykonawczego, a w szczególności: 
                    </p>
                    <ul>
                        <li>
                            Reprezentacja na każdym etapie postępowania w sprawach karnych
                        </li>
                        <li>
                            Występowanie w charakterze obrońcy, pełnomocnika oskarżyciela posiłkowego oraz oskarżyciela prywatnego
                        </li>
                        <li>
                            Sporządzanie środków zaskarżenia: zażalenia, apelacje i kasacje
                        </li>
                        <li>
                            Formułowanie wniosków m.in. o przedterminowe zwolnienie, o odroczenie wykonania kary pozbawienia wolności lub udzielenie przerwy, czy też o dozór elektroniczny
                        </li>
                        <li>
                            Dochodzenie odszkodowania za niesłuszne pozbawienie wolności
                        </li>
                    </ul>
                    <p>
                        Jeśli potrzebują Państwo pomocy w sprawie karnej, zapraszamy do kontaktu i zapoznania się z ofertą Kancelarii w tym zakresie. 
                    </p>
                </div>
            </>
        );
    }
}

export default PrawoKarne;