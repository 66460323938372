import { Container, Col, Row } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./Kancelaria.css";
import ImgSlider from "../ImgSlider/ImgSlider.js";

function Kancelaria() {
    return (
        <div className="kan-body">
            <Container>
                <div className="kan-container">
                    <Row>
                        <Col xs={{ span: 12, order: 2 }} lg={{ span: 6, order: 1 }}>
                            <div className="kan-img">
                                <ImgSlider />
                            </div>
                        </Col>
                        <Col xs={{ span: 12, order: 1 }} lg={{ span: 6, order: 2 }}>
                            <div className="kan-title">
                                Kancelaria
                            </div>
                            <div className="kan-line">
                                <hr/>
                            </div>    
                            <div className="kan-subtitle">
                                Adwokat Jakub Boruta
                            </div>                       
                            <div className="kan-content">
                                <p>
                                    Absolwent Wydziału Prawa Uniwersytetu w Białymstoku. Ukończył aplikację adwokacką przy Okręgowej Radzie Adwokackiej w Białymstoku. 
                                    W trakcie odbywania aplikacji zdobył doświadczenie pod patronatem renomowanej kancelarii adwokackiej, której głównym przedmiotem działalności 
                                    było prawo karne oraz cywilne, rodzinne i spadkowe. Współpracował także z kancelarią adwokacką zajmującą się prawem gospodarczym i obsługą 
                                    przedsiębiorców.
                                </p>
                                <p>
                                    Zdobył doświadczenie przy udzielaniu pomocy prawnej oraz reprezentowaniu klientów indywidualnych, małych i dużych przedsiębiorców przed sądami, 
                                    i innymi instytucjami państwowymi. Główne zainteresowanie zawodowe to prawo karne, cywilne, gospodarcze, 
                                    a w szczególności obsługa przedsiębiorców oraz prawo rodzinne, spadkowe i medyczne. Zdobyte doświadczenie 
                                    pozwala na świadczenie pomocy prawnej na najwyższym poziomie.
                                </p>
                                <p>
                                    Kancelaria adwokata Jakuba Boruta współpracuje z doświadczonymi adwokatami, radcami prawnymi, doradcami podatkowymi oraz notariuszami i komornikami, 
                                    co zapewnia kompleksową usługę w każdym zakresie.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>          
        </div>
    );
}

export default Kancelaria;