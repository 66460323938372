import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Services.css';

class Mediacje extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        document.title = 'Mediacje - Kancelaria Adwokacka - adw. Jakub Boruta';
        window.scrollTo(0, 0)
    }
    render() {
        return (
            <>
                <div className="services-title">
                    Mediacje
                </div>
                <div className="services-line">
                    <hr />
                </div>
                <div className="services-content">
                    <p>
                        Adwokat Jakub Boruta świadczy także usługi w zakresie postępowań mediacyjnych. Jest on certyfikowanym mediatorem, wpisanym na listę mediatorów Centrum Mediacji przy Naczelnej Radzie Adwokackiej. W swojej praktyce promuje mediacje jako bardzo wartościową i korzystną metodę polubownego załatwienia sprawy pomiędzy stronami.
                    </p>
                    <p>
                        Adwokat Jakub Boruta prowadzi mediacje w sprawach:
                    </p>
                    <ul>
                        <li>
                            Gospodarczych
                        </li>
                        <li>
                            Cywilnych
                        </li>
                        <li>
                            Rodzinnych i spadkowych
                        </li>
                        <li>
                            Karnych
                        </li>
                    </ul>
                    <p>
                        Jeśli potrzebują Państwo pomocy z uwagi na powstały spór, a są Państwo zainteresowani polubownym jego zakończeniem i ustaleniem warunków ugody, zapraszamy do kontaktu i zapoznania się z ofertą Kancelarii w tym zakresie. 
                    </p>
                </div>
            </>
        );
    }
}

export default Mediacje;