import { Col, Row } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Header.css';

function Header() {
    return (
        <Row className="header-body">
            <Col className="align-self-center">
                <h4>Kancelaria Adwokacka</h4>
                <h1>adw. Jakub Boruta</h1>
                <h4>w Białymstoku</h4>
            </Col>
        </Row>
    );
}

export default Header;